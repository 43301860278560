@import 'import';
// $width-xss: 327px;
$width-md: 688px;
$width-lg: 804px;
$width-xl: 862px;
$width-xxl: 1194px;
$width-wide-start: $width-lg;

@mixin layoutWide($from-width: $width-wide-start) {
  @container (width >= #{$from-width}) {
    @content;
  }
}

.root {
  --billboard-aspect-ratio: 5 / 6;
  --billboard-content-offset: 20px;
  --billboard-content-overlay-bg: transparent;
  --billboard-content-overlay-border: transparent;
  --billboard-content-padding-x: 20px;
  --billboard-content-padding-y: 20px;
  --billboard-content-width: unset;
  --billboard-cta-offset: 15px;
  --billboard-eyebrow-offset: 0;
  --billboard-textual-gap: 8px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  aspect-ratio: var(--billboard-aspect-ratio);
  width: 100%;

  border-radius: 8px;

  transition: background-color 0.7s ease;

  &:not(:has(.picture)) {
    background-color: $lightgrey2;
  }

  @include layoutWide(385px) {
    --billboard-aspect-ratio: 4 / 3;
  }

  @include layoutWide(520px) {
    --billboard-aspect-ratio: 5 / 3;
  }

  @include layoutWide() {
    --billboard-aspect-ratio: 13 / 3;
    --billboard-content-offset: calc(4% - 7.75px);
    --billboard-content-padding-x: 20px;
    --billboard-content-padding-y: 20px;
    --billboard-content-width: calc(23% + 125px);
    --billboard-cta-offset: 6px;
    --billboard-eyebrow-offset: 6px;
    --billboard-textual-gap: 0;

    flex-direction: row;
    height: unset;

    &:global(._align-left) {
      justify-content: flex-start;
    }

    &:global(._align-right) {
      justify-content: flex-end;
    }
  }

  @include layoutWide($width-lg) {
    --billboard-content-padding-y: 15px;
  }

  @include layoutWide($width-xl) {
    --billboard-content-padding-y: 24px;
  }

  @include layoutWide($width-xxl) {
    --billboard-content-padding-y: 40px;
    --billboard-cta-offset: 8px;
    --billboard-eyebrow-offset: 8px;
  }

  &:global(._theme-lightMode) {
    --billboard-content-color: #{$white};
  }

  &:global(._theme-darkMode) {
    --billboard-content-color: #{$black};
  }

  &:global(._appearance-standard) {
    --billboard-content-border: #{0px}; /* "0px", not "0" */
  }

  &:global(._appearance-adaLockup) {
    --billboard-content-border: 6px;

    @include layoutWide() {
      --billboard-content-border: 8px;
    }

    &:global(._theme-lightMode) {
      --billboard-content-overlay-bg: #{rgba($black, 0.8)};
      --promobanner-cta-arrow-color: #{$white};
    }

    &:global(._theme-darkMode) {
      --billboard-content-overlay-bg: #{rgba($white, 0.8)};
    }

    &:global(._overlay-virginRed) {
      --billboard-content-overlay-border: #{$vv-red};
    }

    &:global(._overlay-rockstar) {
      --billboard-content-overlay-border: #{$brass-cabin};
    }

    &:global(._overlay-uplift) {
      --billboard-content-overlay-border: #{$uplift};
    }

    &:global(._overlay-squidInkLight) {
      --billboard-content-overlay-border: #{$purple};
    }

    &:global(._overlay-oceanBlue) {
      --billboard-content-overlay-border: #{$blue-purple};
    }
  }

  &:has(.eyebrow) {
    .content {
      margin-top: 65px;
    }
  }
}

.picture {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 50% 50%;
  border-radius: inherit;
}

.content {
  z-index: 2;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: fit-content;
  margin: 0 0 var(--billboard-content-offset) 0;
  padding: var(--billboard-content-padding-y) var(--billboard-content-padding-x);

  color: var(--billboard-content-color);

  background-color: var(--billboard-content-overlay-bg);
  border: solid var(--billboard-content-overlay-border);
  border-width: var(--billboard-content-border) 0 0 0;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include layoutWide() {
    width: calc(var(--billboard-content-width) + var(--billboard-content-border));
    min-height: 100%;
    padding: calc(var(--billboard-content-padding-y) / 2) var(--billboard-content-padding-x);

    .root:global(._align-left) & {
      margin: 0 0 0 var(--billboard-content-offset);
      border-width: 0 var(--billboard-content-border) 0 0;
    }

    .root:global(._align-right) & {
      margin: 0 var(--billboard-content-offset) 0 0;
      border-width: 0 0 0 var(--billboard-content-border);
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: fit-content;
  min-height: calc(100% - 3 * var(--billboard-content-padding-y));

  .root:global(._align-right) & {
    align-items: flex-end;
    text-align: right;

    @include layoutWide() {
      align-items: start;
      text-align: left;
    }
  }
}

.eyebrow {
  position: absolute;
  top: 31px;
  flex: 0 0 auto;

  .root:global(._align-left) & {
    left: 20px;
  }

  .root:global(._align-right) & {
    right: 20px;
  }

  @include layoutWide() {
    position: static;
    top: unset;
    right: unset;
    left: unset;

    margin: 0 0 var(--billboard-eyebrow-offset);
  }
}

.textual {
  flex: 0 1 auto;
}

.title {
  margin: 0 0 var(--billboard-textual-gap) 0;

  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: -0.56px;

  @include layoutWide($width-lg) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.75px;
  }

  @include layoutWide($width-xxl) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1px;
  }
}

.description {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;

  @include layoutWide($width-lg) {
    font-size: 13px;
    line-height: 18px;
  }

  @include layoutWide($width-xl) {
    font-size: 14px;
    line-height: 18px;
  }

  @include layoutWide($width-xxl) {
    font-size: 17px;
    line-height: 24px;
  }
}

.cta {
  flex: 0 0 auto;
  margin: var(--billboard-cta-offset) 2px 2px;
}
